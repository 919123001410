.App {
    font-family: sans-serif;
  }
  
  .header {
    text-align: left;
    margin-bottom: 10px;
  }
  
  .carousel-container {
    margin: 20px;
  }
  
  .selected-image {
    width: 100%;
    height: 500px;
    margin-bottom: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .carousel {
    position: relative;
  }
  
  .carousel__images {
    display: flex;
    max-width: 100%;
    overflow-x: hidden;
  }
  
  .carousel__image-selected {
    border: 3px solid #ffa700 !important;
  }
  
  .carousel__image {
    margin-right: 10px;
    height: 150px;
    min-width: 150px;
    border: 3px solid #ffa70000;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .carousel__button {
    position: absolute;
    top: 40%;
    border-radius: 50%;
    box-shadow: none;
    border: none;
    background-color: #556EE6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:4px;
  }
  
  .carousel__button-left {
    left: 10px;
  }
  
  .carousel__button-right {
    right: 10px;
  }
  